<template>
  <div class="alert alert-info">
    <h3>{{ $t('bearings.headlineHelp') }}</h3>
    <p v-html="$t('bearings.contentHelp')"></p>
  </div>
</template>

<script>

export default {};
</script>

<style scoped lang="scss">

</style>
