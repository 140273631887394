<template>
  <div class="panel has-bg">
    <h3>{{ $t('bearings.headlineHoles') }}</h3>
    <div class="row">
      <div class="col-sm-6">
        <b-form-group
          label-for="holesDiameter"
        >
          <template slot="label">
            {{ $t('bearings.holesDiameter') }} <span class="text-muted">d</span>
          </template>
          <b-input-group append="mm">
            <FormInputInteger
              id="holesDiameter"
              v-model="holesDiameter"
              @blur.native="updateVisual"
            />
          </b-input-group>
        </b-form-group>
      </div>
      <div class="col-sm-6">
        <b-form-group
          label-for="holesCount"
        >
          <template slot="label">
            {{ $t('bearings.holesCount') }} <span class="text-muted">n</span>
            <b-button
              id="popover-holes-count"
              variant="default"
            >
              <font-awesome-icon icon="question-circle" />
            </b-button>
            <b-popover
              target="popover-holes-count"
              triggers="hover"
              placement="top"
            >
              <p>{{ $t('bearings.holesCountTooltip') }}</p>
              <p v-html="$t('general.tooltipAdditionalInfo')"></p>
            </b-popover>
          </template>
          <b-input-group>
            <b-select
              id="holesCount"
              v-model="holesCount"
              :options="holesCountOptions"
              @change="checkPositions"
              @blur.native="updateVisual"
            />
          </b-input-group>
        </b-form-group>
      </div>
    </div>
    <div v-if="holesCount > 0">
      <p>{{ $t('bearings.holesLocation') }}</p>
      <div class="row">
        <div class="col-sm-6 col-xxl-3">
          <b-form-group
            label-for="holesX1"
          >
            <template slot="label">
              <span class="text-muted">x1</span>
            </template>
            <b-input-group append="mm">
              <FormInputInteger
                :options="{minimumValue: -10000000}"
                id="holesX1"
                v-model="x1"
                type="text"
                @blur.native="updateVisual"
              />
            </b-input-group>
          </b-form-group>
        </div>
        <div class="col-sm-6 col-xxl-3">
          <b-form-group
            label-for="holesY1"
          >
            <template slot="label">
              <span class="text-muted">y1</span>
            </template>
            <b-input-group append="mm">
              <FormInputInteger
                :options="{minimumValue: -10000000}"
                id="holesY1"
                v-model="y1"
                type="text"
                @blur.native="updateVisual"
              />
            </b-input-group>
          </b-form-group>
        </div>
        <div v-if="holesCount > 1" class="col-sm-6 col-xxl-3">
          <b-form-group
            label-for="holesX2"
          >
            <template slot="label">
              <span class="text-muted">x2</span>
            </template>
            <b-input-group append="mm">
              <FormInputInteger
                :options="{minimumValue: -10000000}"
                id="holesX2"
                v-model="x2"
                type="text"
                @blur.native="updateVisual"
              />
            </b-input-group>
          </b-form-group>
        </div>
        <div v-if="holesCount > 1" class="col-sm-6 col-xxl-3">
          <b-form-group
            label-for="holesY2"
          >
            <template slot="label">
              <span class="text-muted">y2</span>
            </template>
            <b-input-group append="mm">
              <FormInputInteger
                :options="{minimumValue: -10000000}"
                id="holesY2"
                v-model="y2"
                @blur.native="updateVisual"
              />
            </b-input-group>
          </b-form-group>
        </div>
      </div>
    </div>
    <div v-if="holesCount > 2">
      <div class="row">
        <div class="col-sm-6 col-xxl-3">
          <b-form-group
            label-for="holesX3"
          >
            <template slot="label">
              <span class="text-muted">x3</span>
            </template>
            <b-input-group append="mm">
              <FormInputInteger
                :options="{minimumValue: -10000000}"
                id="holesX3"
                v-model="x3"
                type="text"
                @blur.native="updateVisual"
              />
            </b-input-group>
          </b-form-group>
        </div>
        <div class="col-sm-6 col-xxl-3">
          <b-form-group
            label-for="holesY3"
          >
            <template slot="label">
              <span class="text-muted">y3</span>
            </template>
            <b-input-group append="mm">
              <FormInputInteger
                :options="{minimumValue: -10000000}"
                id="holesY3"
                v-model="y3"
                type="text"
                @blur.native="updateVisual"
              />
            </b-input-group>
          </b-form-group>
        </div>
        <div v-if="holesCount > 3" class="col-sm-6 col-xxl-3">
          <b-form-group
            label-for="holesX4"
          >
            <template slot="label">
              <span class="text-muted">x4</span>
            </template>
            <b-input-group append="mm">
              <FormInputInteger
                :options="{minimumValue: -10000000}"
                id="holesX4"
                v-model="x4"
                type="text"
                @blur.native="updateVisual"
              />
            </b-input-group>
          </b-form-group>
        </div>
        <div v-if="holesCount > 3" class="col-sm-6 col-xxl-3">
          <b-form-group
            label-for="holesY4"
          >
            <template slot="label">
              <span class="text-muted">y4</span>
            </template>
            <b-input-group append="mm">
              <FormInputInteger
                :options="{minimumValue: -10000000}"
                id="holesY4"
                v-model="y4"
                @blur.native="updateVisual"
              />
            </b-input-group>
          </b-form-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OutputHelper from '@/helpers/OutputHelper';
import FormInputInteger from '../app/form/FormInputInteger.vue';

export default {
  components: {
    FormInputInteger,
  },
  data() {
    return {
      holesCountOptions: this.$dataOptionsHelper.getBearingHolesOptions(),
    };
  },
  computed: {
    holesDiameter: {
      get() {
        return this.$store.state.fixedBearingHolesDiameter;
      },
      set(value) {
        this.$store.commit('fixedBearingHolesDiameter', value);
      },
    },
    holesCount: {
      get() {
        return this.$store.state.fixedBearingHolesCount;
      },
      set(value) {
        this.$store.commit('fixedBearingHolesCount', value);
      },
    },
    holesPosition: {
      get() {
        return this.$store.state.fixedBearingHolesPosition;
      },
      set(value) {
        this.$store.commit('fixedBearingHolesPosition', value);
      },
    },
    x1: {
      get() {
        return this.$store.state.fixedBearingX1;
      },
      set(value) {
        this.$store.commit('fixedBearingX1', OutputHelper.convertToInteger(value));
      },
    },
    y1: {
      get() {
        return this.$store.state.fixedBearingY1;
      },
      set(value) {
        this.$store.commit('fixedBearingY1', OutputHelper.convertToInteger(value));
      },
    },
    x2: {
      get() {
        return this.$store.state.fixedBearingX2;
      },
      set(value) {
        this.$store.commit('fixedBearingX2', OutputHelper.convertToInteger(value));
      },
    },
    y2: {
      get() {
        return this.$store.state.fixedBearingY2;
      },
      set(value) {
        this.$store.commit('fixedBearingY2', OutputHelper.convertToInteger(value));
      },
    },
    x3: {
      get() {
        return this.$store.state.fixedBearingX3;
      },
      set(value) {
        this.$store.commit('fixedBearingX3', OutputHelper.convertToInteger(value));
      },
    },
    y3: {
      get() {
        return this.$store.state.fixedBearingY3;
      },
      set(value) {
        this.$store.commit('fixedBearingY3', OutputHelper.convertToInteger(value));
      },
    },
    x4: {
      get() {
        return this.$store.state.fixedBearingX4;
      },
      set(value) {
        this.$store.commit('fixedBearingX4', OutputHelper.convertToInteger(value));
      },
    },
    y4: {
      get() {
        return this.$store.state.fixedBearingY4;
      },
      set(value) {
        this.$store.commit('fixedBearingY4', OutputHelper.convertToInteger(value));
      },
    },
  },
  methods: {
    checkPositions() {
      const pos = this.$calcHelper.getHolesPosition(
        this.$store.state.fixedBearingWidth,
        this.$store.state.fixedBearingLength,
        this.$store.state.fixedBearingHolesCount,
        this.$store.state.fixedBearingHolesDiameter,
      );

      this.x1 = pos.hole1?.x ?? 0;
      this.y1 = pos.hole1?.y ?? 0;
      this.x2 = pos.hole2?.x ?? 0;
      this.y2 = pos.hole2?.y ?? 0;
      this.x3 = pos.hole3?.x ?? 0;
      this.y3 = pos.hole3?.y ?? 0;
      this.x4 = pos.hole4?.x ?? 0;
      this.y4 = pos.hole4?.y ?? 0;
    },
    updateVisual() {
      const value = this.$store.state.fixedBearingUpdateVisual + 1;
      this.$store.commit('fixedBearingUpdateVisual', value);
    },
  },
};
</script>

<style scoped lang="scss">

</style>
