<template>
  <div class="panel">
    <div class="row">
      <div class="col-8">
        <h3>{{ $t('bearings.headlineDialog') }}</h3>
        <p class="text-muted">
          {{ $t('bearings.subHeadlineDialog') }}
        </p>
      </div>
      <div class="col-4 text-right">
        <b-button
          variant="primary"
          size="sm"
          @click="toggleContent"
        >
          <span v-show="!isOpen">{{ $t('general.more') }}</span>
          <span v-show="isOpen">{{ $t('general.less') }}</span>
        </b-button>
      </div>
    </div>
    <b-collapse
      id="dialogContent"
      v-model="isOpen"
    >
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th></th>
              <th>&sigma; <span class="text-muted"><sub>z,Rd</sub></span></th>
              <th>&alpha; <span class="text-muted"><sub>a</sub></span></th>
              <th>&alpha; <span class="text-muted"><sub>b</sub></span></th>
              <th>
                &alpha;
                <span class="text-muted">
                  <sub>{{ $t('bearings.dialogAlphaResult') }}</sub>
                </span>
              </th>
              <th class="text-center">{{ $t('bearings.dialogDimensions') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ $t('general.type') }} 100</td>
              <td :class="{ 'table-danger': errorLoadCapacityTyp100.status }">
                <span
                  v-if="typ100PermissibleCompressiveStress > 0 &&
                    typ100PermissibleCompressiveStress < 14"
                  :class="{ 'text-danger': errorLoadCapacityTyp100.status }"
                >
                  {{ typ100PermissibleCompressiveStress | numeralFormat('0.0') }} N/mm <sup>2</sup>
                </span>
                <span
                  v-if="typ100PermissibleCompressiveStress >= 14"
                  :class="{ 'text-danger': errorLoadCapacityTyp100.status }"
                >
                  {{ 14 | numeralFormat('0.0') }} N/mm <sup>2</sup>
                </span>
                <span
                  v-if="typ100PermissibleCompressiveStress < 0"
                  :class="{ 'text-danger': errorLoadCapacityTyp100.status }"
                >
                  &lt; 0 N/mm <sup>2</sup>
                </span>
              </td>
              <td :class="{ 'table-danger': errorAlphaATyp100.status }">
                <span :class="{ 'text-danger': errorAlphaATyp100.status }">
                  {{ typ100PermissibleTwisting.alphaA | numeralFormat('0.0') }} &permil;
                </span>
              </td>
              <td :class="{ 'table-danger': errorAlphaBTyp100.status }">
                <span :class="{ 'text-danger': errorAlphaBTyp100.status }">
                  {{ typ100PermissibleTwisting.alphaB | numeralFormat('0.0') }} &permil;
                </span>
              </td>
              <td :class="{ 'table-danger': errorAlphaResultingTyp100.status }">
                <span :class="{ 'text-danger': errorAlphaResultingTyp100.status }">
                  {{ typ100PermissibleAlphaResulting | numeralFormat('0.0') }} &permil;
                </span>
              </td>
              <td class="text-center">
                <b-badge
                  :inner-html.prop="typ100isPossibleMsg.value | abbr"
                  :variant="`${typ100isPossibleMsg.variant}`"
                >
                </b-badge>
              </td>
            </tr>
            <tr>
              <td>{{ $t('general.type') }} 150</td>
              <td :class="{ 'table-danger': errorLoadCapacityTyp150.status }">
                <span
                  v-if="typ150PermissibleCompressiveStress > 0 &&
                    typ150PermissibleCompressiveStress < 21"
                  :class="{ 'text-danger': errorLoadCapacityTyp150.status }"
                >
                  {{ typ150PermissibleCompressiveStress | numeralFormat('0.0') }} N/mm <sup>2</sup>
                </span>
                <span
                  v-if="typ150PermissibleCompressiveStress >= 21"
                  :class="{ 'text-danger': errorLoadCapacityTyp150.status }"
                >
                  {{ 21 | numeralFormat('0.0') }} N/mm <sup>2</sup>
                </span>
                <span
                  v-if="typ150PermissibleCompressiveStress < 0"
                  :class="{ 'text-danger': errorLoadCapacityTyp150.status }"
                >
                  &lt; 0 N/mm <sup>2</sup>
                </span>
              </td>
              <td :class="{ 'table-danger': errorAlphaATyp150.status }">
                <span :class="{ 'text-danger': errorAlphaATyp150.status }">
                  {{ typ150PermissibleTwisting.alphaA | numeralFormat('0.0') }} &permil;
                </span>
              </td>
              <td :class="{ 'table-danger': errorAlphaBTyp150.status }">
                <span :class="{ 'text-danger': errorAlphaBTyp150.status }">
                  {{ typ150PermissibleTwisting.alphaB | numeralFormat('0.0') }} &permil;
                </span>
              </td>
              <td :class="{ 'table-danger': errorAlphaResultingTyp150.status }">
                <span :class="{ 'text-danger': errorAlphaResultingTyp150.status }">
                  {{ typ150PermissibleAlphaResulting | numeralFormat('0.0') }} &permil;
                </span>
              </td>
              <td class="text-center">
                <b-badge
                  :inner-html.prop="typ150isPossibleMsg.value | abbr"
                  :variant="`${typ150isPossibleMsg.variant}`"
                >
                </b-badge>
              </td>
            </tr>
            <tr>
              <td>{{ $t('general.type') }} 200</td>
              <td :class="{ 'table-danger': errorLoadCapacityTyp200.status }">
                <span
                  v-if="typ200PermissibleCompressiveStress > 0 &&
                    typ200PermissibleCompressiveStress < 28"
                  :class="{ 'text-danger': errorLoadCapacityTyp200.status }"
                >
                  {{ typ200PermissibleCompressiveStress | numeralFormat('0.0') }} N/mm <sup>2</sup>
                </span>
                <span
                  v-if="typ200PermissibleCompressiveStress >= 28"
                  :class="{ 'text-danger': errorLoadCapacityTyp200.status }"
                >
                  {{ 28 | numeralFormat('0.0') }} N/mm <sup>2</sup>
                </span>
                <span
                  v-if="typ200PermissibleCompressiveStress < 0"
                  :class="{ 'text-danger': errorLoadCapacityTyp200.status }"
                >
                  &lt; 0 N/mm <sup>2</sup>
                </span>
              </td>
              <td :class="{ 'table-danger': errorAlphaATyp200.status }">
                <span :class="{ 'text-danger': errorAlphaATyp200.status }">
                  {{ typ200PermissibleTwisting.alphaA | numeralFormat('0.0') }} &permil;
                </span>
              </td>
              <td :class="{ 'table-danger': errorAlphaBTyp200.status }">
                <span :class="{ 'text-danger': errorAlphaBTyp200.status }">
                  {{ typ200PermissibleTwisting.alphaB | numeralFormat('0.0') }} &permil;
                </span>
              </td>
              <td :class="{ 'table-danger': errorAlphaResultingTyp200.status }">
                <span :class="{ 'text-danger': errorAlphaResultingTyp200.status }">
                  {{ typ200PermissibleAlphaResulting | numeralFormat('0.0') }} &permil;
                </span>
              </td>
              <td class="text-center">
                <b-badge
                  :inner-html.prop="typ200isPossibleMsg.value | abbr"
                  :variant="`${typ200isPossibleMsg.variant}`"
                >
                  {{ typ200isPossibleMsg.value }}
                </b-badge>
              </td>
            </tr>
          </tbody>
          <!--
          <thead>
            <tr>
              <th></th>
              <th>&sigma; <span class="text-muted"><sub>z,Rk</sub></span></th>
              <th>
                {{ $t('bearings.dialogPyramideAllowedAlpha') }} &alpha;
              </th>
              <th></th>
              <th></th>
              <th>{{ $t('bearings.dialogDimensions') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ $t('bearings.dialogPyramideBearing') }}</td>
              <td :class="{ 'table-danger': errorLoadCapacityPyramide.status }">
                <span :class="{ 'text-danger': errorLoadCapacityPyramide.status }">
                  {{ pyramidePermissibleCompressiveStress | numeralFormat('0.0') }}
                  N/mm <sup>2</sup>
                </span>
              </td>
              <td :class="{ 'table-danger': errorTwistingPyramide.status }">
                <span :class="{ 'text-danger': errorTwistingPyramide.status }">
                  {{ pyramidePermissibleTwisting | numeralFormat('0.0') }} &permil;
                </span>
              </td>
              <td></td>
              <td></td>
              <td>
                <b-badge
                  :inner-html.prop="pyramideIsPossibleMsg.value | abbr"
                  :variant="`${pyramideIsPossibleMsg.variant}`"
                >
                  {{ pyramideIsPossibleMsg.value }}
                </b-badge>
              </td>
            </tr>
          </tbody>
          -->
        </table>
      </div>
      <p><strong>{{ $t('bearings.dialogMoreData') }}</strong></p>
      <div class="table-responsive">
        <table class="table table-striped">
          <tbody>
            <tr>
              <td>{{ $t('bearings.dialogDimension') }}</td>
              <td class="text-right">
                <span class="text-muted">{{ dimension }} mm<sup>3</sup></span>
              </td>
            </tr>
            <tr>
              <td>A<sub>{{ $t('bearings.dialogDimensionBrutto') }}</sub></td>
              <td class="text-right">
                <span class="text-muted">
                  {{ areaBrutto | numeralFormat('0') }} mm<sup>2</sup>
                </span>
              </td>
            </tr>
            <tr>
              <td>A<sub>{{ $t('bearings.dialogDimensionNetto') }}</sub></td>
              <td class="text-right">
                <span class="text-muted">{{ areaNetto | numeralFormat('0') }} mm<sup>2</sup></span>
              </td>
            </tr>
            <tr>
              <td>A<sub>{{ $t('bearings.dialogDimensionHoles') }}</sub></td>
              <td class="text-right">
                <span class="text-muted">{{ areaHoles | numeralFormat('0') }} mm<sup>2</sup></span>
              </td>
            </tr>
            <tr>
              <td>{{ $t('bearings.dialogFormFactor') }} <span class="text-muted">S</span></td>
              <td class="text-right">
                <span
                  v-if="holesCount === 0"
                  class="text-muted"
                >
                  {{ formFactor.formFactor| numeralFormat('0.00') }}
                </span>
                <span
                  v-if="holesCount > 0"
                  class="text-muted"
                >
                  {{ formFactor.formFactorDrilling | numeralFormat('0.00') }}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('bearings.dialogCompressiveStress') }}
                <span class="text-muted">&sigma; <sub>z,Ed</sub></span>
              </td>
              <td class="text-right">
                <span class="text-muted">
                  {{ compressiveStress | numeralFormat('0.0') }} N/mm <sup>2</sup>
                </span>
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('bearings.dialogTwisting') }}
                <span class="text-muted">
                  &angle; {{ $t('bearings.dialogTwistingTotal') }}
                </span>
              </td>
              <td class="text-right">
                <span class="text-muted">
                  {{ twistingTotal | numeralFormat('0.0') }} &permil;
                </span>
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('bearings.dialogAngle') }}
                <span class="text-muted">&alpha;</span>
              </td>
              <td class="text-right">
                <span class="text-muted">
                  {{ angleOfRotation | numeralFormat('0.00000') }} &deg;
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'FixedBearingsFormDialogDimension',
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    ...mapGetters([
      'warningStatusAreaHoles',
      'warningStatusMaxDimensions',
      'twistingTotal',
      'compressiveStress',
      'areaBrutto',
      'areaNetto',
      'areaHoles',
      'formFactor',
      'angleOfRotation',
      'typ100PermissibleCompressiveStress',
      'typ100PermissibleTwisting',
      'typ100PermissibleAlphaResulting',
      'typ150PermissibleCompressiveStress',
      'typ150PermissibleTwisting',
      'typ150PermissibleAlphaResulting',
      'typ200PermissibleCompressiveStress',
      'typ200PermissibleTwisting',
      'typ200PermissibleAlphaResulting',
      'errorDimensions',
      'errorDimensionsTyp100',
      'errorAlphaATyp100',
      'errorAlphaBTyp100',
      'errorAlphaResultingTyp100',
      'errorLoadCapacityTyp100',
      'typ100IsPossibile',
      'errorDimensionsTyp150',
      'errorAlphaATyp150',
      'errorAlphaBTyp150',
      'errorAlphaResultingTyp150',
      'errorLoadCapacityTyp150',
      'typ150IsPossibile',
      'errorDimensionsTyp200',
      'errorAlphaATyp200',
      'errorAlphaBTyp200',
      'errorAlphaResultingTyp200',
      'errorLoadCapacityTyp200',
      'typ200IsPossibile',
      'errorDimensionsPyramide',
      'errorTwistingPyramide',
      'errorLoadCapacityPyramide',
      'pyramideIsPossibile',
      'pyramidePermissibleCompressiveStress',
      'pyramidePermissibleTwisting',
      'pyramideCompressiveStressMin',
      'pyramideCompressiveStressMax',
      'pyramideAngleOfRotation',
      'pyramideTwistingTotal',
      'pyramideAngleOfRotationTotal',
    ]),
    holesCount() {
      return this.$store.state.fixedBearingHolesCount;
    },
    dimension() {
      return `${this.$store.state.fixedBearingWidth} x ${this.$store.state.fixedBearingLength} x ${this.$store.state.fixedBearingThickness}`;
    },
    warningMsg() {
      let msg = '';
      if (this.warningStatusAreaHoles.status) {
        msg = this.$i18n.t(`warningCodes.${this.warningStatusAreaHoles.errorCodes[0]}`);
      }
      if (this.warningStatusMaxDimensions.status) {
        msg = this.$i18n.t(`warningCodes.${this.warningStatusMaxDimensions.errorCodes[0]}`);
      }
      return msg;
    },
    typ100isPossibleMsg() {
      const msg = {
        variant: 'success',
        value: this.$i18n.t('general.possible'),
      };
      if (this.warningMsg.length) {
        msg.variant = 'warning';
        msg.value = this.warningMsg;
      }
      if (this.errorDimensionsTyp100.status) {
        msg.variant = 'danger';
        msg.value = this.$i18n.t('general.notPossible');
      }
      return msg;
    },
    typ150isPossibleMsg() {
      const msg = {
        variant: 'success',
        value: this.$i18n.t('general.possible'),
      };
      if (this.warningMsg.length) {
        msg.variant = 'warning';
        msg.value = this.warningMsg;
      }
      if (this.errorDimensionsTyp150.status) {
        msg.variant = 'danger';
        msg.value = this.$i18n.t('general.notPossible');
      }
      return msg;
    },
    typ200isPossibleMsg() {
      const msg = {
        variant: 'success',
        value: this.$i18n.t('general.possible'),
      };
      if (this.warningMsg.length) {
        msg.variant = 'warning';
        msg.value = this.warningMsg;
      }
      if (this.errorDimensionsTyp200.status) {
        msg.variant = 'danger';
        msg.value = this.$i18n.t('general.notPossible');
      }
      return msg;
    },
    pyramideIsPossibleMsg() {
      const msg = {
        variant: 'success',
        value: this.$i18n.t('general.possible'),
      };
      if (this.errorDimensionsPyramide.status) {
        msg.variant = 'danger';
        msg.value = this.$i18n.t('general.notPossible');
      }
      return msg;
    },
  },
  methods: {
    toggleContent() {
      this.isOpen = (this.isOpen === false);
    },
  },
};
</script>

<style scoped lang="scss">

</style>
