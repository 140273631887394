<template>
  <div class="panel has-bg">
    <h3>{{ $t('bearings.headlineEffects') }}</h3>
    <!-- Kraft -->
    <div class="row">
      <div class="col-sm-6">
        <b-form-group
          label-for="power"
        >
          <template slot="label">
            {{ $t('bearings.power') }} <span class="text-muted">F<sub>z,d</sub></span>
            <b-button
              id="popover-power"
              variant="default"
            >
              <font-awesome-icon icon="question-circle" />
            </b-button>
            <b-popover
              target="popover-power"
              triggers="hover"
              placement="top"
            >
              <template v-slot:title>
                {{ $t('bearings.powerTooltipHeadline') }}
              </template>
              <p>{{ $t('bearings.powerTooltip') }}</p>
              <p v-html="$t('general.tooltipAdditionalInfo')"></p>
            </b-popover>
          </template>
          <b-input-group append="kN">
            <FormInputDecimal
              id="power"
              v-model="power"
              type="text"
              decimal-places="1"
            >
            </FormInputDecimal>
          </b-input-group>
        </b-form-group>
      </div>
      <div class="col-sm-6">
        <b-form-group
          label-for="power"
        >
          <template slot="label">
            {{ $t('bearings.typeContactSurface') }}
          </template>
          <b-input-group>
            <b-form-select
              v-model="typeContactSurface"
              :options="typeContactSurfaceOptions"
            >
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </div>
    </div>
    <!-- Auflagerverdrehung -->
    <div class="row">
      <!-- Auflagerverdrehung a -->
      <div class="col-md-6">
        <b-form-group
          label-for="twistingA"
        >
          <template slot="label">
            {{ $t('bearings.twisting') }}
            <span class="text-muted">&alpha; <sub>a,d</sub></span>
            <b-button
              id="popover-twisting-a"
              variant="default"
            >
              <font-awesome-icon icon="question-circle" />
            </b-button>
            <b-popover
              target="popover-twisting-a"
              triggers="hover"
              placement="top"
            >
              <template v-slot:title>
                {{ $t('bearings.twistingTooltipHeadline') }}
              </template>
              <p>{{ $t('bearings.twistingTooltip',{'site' : 'a'}) }}</p>
              <p v-html="$t('general.tooltipAdditionalInfo')"></p>
            </b-popover>
          </template>
          <b-input-group>
            <template v-slot:append>
              <b-input-group-text>&permil;</b-input-group-text>
            </template>
            <FormInputDecimal
              id="twistingA"
              v-model="twistingA"
              type="text"
              decimal-places="2"
            >
            </FormInputDecimal>
          </b-input-group>
        </b-form-group>
      </div>
      <!-- Auflagerverdrehung b -->
      <div class="col-md-6">
        <b-form-group
          label-for="twistingB"
        >
          <template slot="label">
            {{ $t('bearings.twisting') }}
            <span class="text-muted">&alpha; <sub>b,d</sub></span>
            <b-button
              id="popover-twisting-b"
              variant="default"
            >
              <font-awesome-icon icon="question-circle" />
            </b-button>
            <b-popover
              target="popover-twisting-b"
              triggers="hover"
              placement="top"
            >
              <template v-slot:title>
                {{ $t('bearings.twistingTooltipHeadline') }}
              </template>
              <p>{{ $t('bearings.twistingTooltip',{'site' : 'b'}) }}</p>
              <p v-html="$t('general.tooltipAdditionalInfo')"></p>
            </b-popover>
          </template>
          <b-input-group>
            <template v-slot:append>
              <b-input-group-text>&permil;</b-input-group-text>
            </template>
            <FormInputDecimal
              id="twistingB"
              v-model="twistingB"
              type="text"
              decimal-places="2"
            >
            </FormInputDecimal>
          </b-input-group>
        </b-form-group>
      </div>
    </div>
    <!-- Checkbox Verdrehungszuschlag -->
    <div>
      <b-form-checkbox
        id="withTwistSurcharge"
        v-model="twistingWithSurcharge"
        name="withTwistSurcharge"
      >
        {{ $t('bearings.withTwistSurcharge') }}
        <b-button
          id="popover-twist-surcharge"
          variant="default"
        >
          <font-awesome-icon icon="question-circle" />
        </b-button>
        <b-popover
          target="popover-twist-surcharge"
          triggers="hover"
          placement="top"
        >
          <p>
            {{ $t('bearings.withTwistSurchargeTooltip1') }}
            <strong>
              {{ $t('bearings.withTwistSurchargeTooltip2') }}
            </strong>
          </p>
          <p v-html="$t('general.tooltipAdditionalInfo')"></p>
        </b-popover>
      </b-form-checkbox>
    </div>
    <!-- Verdrehungszuschlag -->
    <div
      v-if="twistingWithSurcharge"
      class="mt-2"
    >
      <div class="row">
        <!-- Verdrehungszuschlag Schiefwinklig -->
        <div class="col-md-6">
          {{ $t('bearings.twistingSurcharge') }}
          <span class="text-muted">
            &alpha; <sub>{{ $t('bearings.twistingSurchargeAskew') }} </sub>
          </span>
          <span class="text-muted">
            {{ twistingSurchargeAskew | numeralFormat('0.0') }} &permil;
          </span>
        </div>
        <!-- Verdrehungszuschlag Unebenheit -->
        <div class="col-md-6">
          {{ $t('bearings.twistingSurcharge') }}
          <span class="text-muted">
            &alpha; <sub>{{ $t('bearings.twistingSurchargeRoughness') }} </sub>
          </span>
          <span class="text-muted">
            {{ twistingSurchargeRoughness | numeralFormat('0.0') }} &permil;
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormInputDecimal from '../app/form/FormInputDecimal.vue';

export default {
  name: 'FixedBearingsFormEffects',
  components: {
    FormInputDecimal,
  },
  data() {
    return {
      twistingSurchargeAskew: 10,
      typeContactSurfaceOptions: Object.values(
        this.$dataOptionsHelper.getTypeContactSurfaceOptions(this.$i18n),
      ),
    };
  },
  computed: {
    twistingSurchargeRoughness() {
      const mainStressedSide = this.$calcHelper.getMainStressedSide(
        this.twistingA,
        this.twistingB,
        this.$store.state.fixedBearingWidth,
        this.$store.state.fixedBearingLength,
        this.$store.state.fixedBearingThickness,
      );
      return this.$calcHelper.getSurchargeRoughness(mainStressedSide, this.typeContactSurface);
    },
    power: {
      get() {
        return this.$store.state.fixedBearingPressureForce;
      },
      set(value) {
        this.$store.commit('fixedBearingPressureForce', value);
      },
    },
    twistingA: {
      get() {
        return this.$store.state.fixedBearingTwistingA;
      },
      set(value) {
        this.$store.commit('fixedBearingTwistingA', value);
      },
    },
    twistingB: {
      get() {
        return this.$store.state.fixedBearingTwistingB;
      },
      set(value) {
        this.$store.commit('fixedBearingTwistingB', value);
      },
    },
    twistingWithSurcharge: {
      get() {
        return this.$store.state.fixedBearingTwistingWithSurcharge;
      },
      set(value) {
        this.$store.commit('fixedBearingTwistingWithSurcharge', value);
      },
    },
    typeContactSurface: {
      get() {
        return this.$store.state.fixedBearingTypeContactSurface;
      },
      set(value) {
        this.$store.commit('fixedBearingTypeContactSurface', value);
      },
    },
  },
};
</script>

<style scoped lang="scss">

</style>
