<template>
  <div class="panel">
    <h3>{{ $t('bearings.headlineSelection') }}</h3>
    <div
      v-if="warningMessages.length"
      class="alert alert-warning"
    >
      <div class="d-flex">
        <div class="mr-3">
          <font-awesome-icon
            icon="exclamation-triangle"
            class="fa-2x"
          />
        </div>
        <div>
          <p><strong>{{ $t('bearings.introWarningMessages') }}:</strong></p>
          <ul>
            <li
              v-for="(item, i) in warningMessages"
              :key="`warningMessages${i}`"
            >
              {{ $t(`warningCodes.${item}`) }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-striped">
        <tbody>
          <tr
            :class="{ 'table-danger': !typ100IsPossibile, 'table-success': typ100IsPossibile }"
          >
            <td>{{ $t('general.type') }} 100</td>
            <td class="text-right">
              <b-badge
                v-if="typ100IsPossibile"
                variant="success"
              >
                {{ $t('general.possible') }}
              </b-badge>
              <b-badge
                v-if="!typ100IsPossibile"
                variant="danger"
              >
                {{ $t('general.notPossible') }}
              </b-badge>
            </td>
            <td class="text-right">
              <b-badge
                v-if="recommended === 'typ100'"
                variant="info"
              >
                {{ $t('general.recommended') }} *
              </b-badge>
            </td>
            <td class="text-right">
              <b-button
                :disabled="!typ100IsPossibile"
                variant="success"
                size="sm"
                @click="openModal('typ100')"
              >
                {{ $t('general.print') }}
              </b-button>
            </td>
          </tr>
          <tr
            :class="{ 'table-danger': !typ150IsPossibile, 'table-success': typ150IsPossibile }"
          >
            <td>{{ $t('general.type') }} 150</td>
            <td class="text-right">
              <b-badge
                v-if="typ150IsPossibile"
                variant="success"
              >
                {{ $t('general.possible') }}
              </b-badge>
              <b-badge
                v-if="!typ150IsPossibile"
                variant="danger"
              >
                {{ $t('general.notPossible') }}
              </b-badge>
            </td>
            <td class="text-right">
              <b-badge
                v-if="recommended === 'typ150'"
                variant="info"
              >
                {{ $t('general.recommended') }} *
              </b-badge>
            </td>
            <td class="text-right">
              <b-button
                :disabled="!typ150IsPossibile"
                variant="success"
                size="sm"
                @click="openModal('typ150')"
              >
                {{ $t('general.print') }}
              </b-button>
            </td>
          </tr>
          <tr
            :class="{ 'table-danger': !typ200IsPossibile, 'table-success': typ200IsPossibile }"
          >
            <td>{{ $t('general.type') }} 200</td>
            <td class="text-right">
              <b-badge
                v-if="typ200IsPossibile"
                variant="success"
              >
                {{ $t('general.possible') }}
              </b-badge>
              <b-badge
                v-if="!typ200IsPossibile"
                variant="danger"
              >
                {{ $t('general.notPossible') }}
              </b-badge>
            </td>
            <td class="text-right">
              <b-badge
                v-if="recommended === 'typ200'"
                variant="info"
              >
                {{ $t('general.recommended') }} *
              </b-badge>
            </td>
            <td class="text-right">
              <b-button
                :disabled="!typ200IsPossibile"
                variant="success"
                size="sm"
                @click="openModal('typ200')"
              >
                {{ $t('general.print') }}
              </b-button>
            </td>
          </tr>
          <!-- <tr
            :class="{ 'table-danger': !pyramideIsPossibile, 'table-success': pyramideIsPossibile }"
          >
            <td>{{ $t('bearings.selectionPyramideBearing') }}</td>
            <td class="text-right">
              <b-badge
                v-if="pyramideIsPossibile"
                variant="success"
              >
                {{ $t('general.possible') }}
              </b-badge>
              <b-badge
                v-if="!pyramideIsPossibile"
                variant="danger"
              >
                {{ $t('general.notPossible') }}
              </b-badge>
            </td>
            <td class="text-right">
              <b-badge
                v-if="recommended === 'pyramide'"
                variant="info"
              >
                {{ $t('general.recommended') }} *
              </b-badge>
            </td>
            <td class="text-right">
              <b-button
                :disabled="!pyramideIsPossibile"
                variant="success"
                size="sm"
                @click="openModal('pyramide')"
              >
                {{ $t('general.print') }}
              </b-button>
            </td>
          </tr> -->
        </tbody>
      </table>
    </div>
    <p class="text-muted">
      * {{ $t('bearings.selectionRecommended') }}
    </p>
    <FixedBearingsModalOutput />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FixedBearingsModalOutput from '@/components/fixedBearings/FixedBearingsModalOutput.vue';

export default {
  name: 'FixedBearingsFormEffects',
  components: {
    FixedBearingsModalOutput,
  },
  data() {
    return {
      typ100IsRecommended: true,
      typ150IsRecommended: false,
      typ200IsRecommended: false,
      pyramideIsRecommended: false,
      modalShow: false,
    };
  },
  computed: {
    ...mapGetters([
      'warningMessages',
      'typ100IsPossibile',
      'typ150IsPossibile',
      'typ200IsPossibile',
      'pyramideIsPossibile',
    ]),
    recommended() {
      let bearingType = '';
      /* if (this.pyramideIsPossibile) {
        bearingType = 'pyramide';
      } else */
      if (
        /* !this.pyramideIsPossibile
        && */
        this.typ100IsPossibile
      ) {
        bearingType = 'typ100';
      } else if (
        /* !this.pyramideIsPossibile
        &&  */
        !this.typ100IsPossibile
        && this.typ150IsPossibile
      ) {
        bearingType = 'typ150';
      } else if (
        /* !this.pyramideIsPossibile
        && */
        !this.typ100IsPossibile
        && !this.typ150IsPossibile
        && this.typ200IsPossibile
      ) {
        bearingType = 'typ200';
      }
      return bearingType;
    },
  },
  methods: {
    openModal(type) {
      this.$store.commit('fixedBearingSelectedBearingType', type);
      this.$bvModal.show('modalOutput');
    },
  },
};
</script>

<style scoped lang="scss">

</style>
