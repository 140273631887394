<template>
  <b-modal id="modalOutput">
    <template v-slot:modal-title>
      {{ $t('bearings.headlineModalOutput') }}
    </template>
    <div class="panel">
      <h3>{{ $t('bearings.headlineGeneral') }}</h3>
      <b-form-group
        :label="$t('bearings.customer')"
        label-for="customer"
      >
        <b-form-textarea
          id="customer"
          v-model="customer"
          type="text"
        >
        </b-form-textarea>
      </b-form-group>
      <b-form-group
        :label="$t('bearings.object')"
        label-for="object"
      >
        <b-form-textarea
          id="object"
          v-model="object"
          type="text"
        >
        </b-form-textarea>
      </b-form-group>
      <div class="row">
        <div class="col-lg-6">
          <b-form-group
            :label="$t('bearings.position')"
            label-for="pos"
          >
            <b-form-input
              id="pos"
              v-model="pos"
              type="text"
            >
            </b-form-input>
          </b-form-group>
        </div>
        <div class="col-lg-6">
          <b-form-group
            :label="$t('bearings.amount')"
            label-for="amount"
          >
            <b-form-input
              id="amount"
              v-model="amount"
              type="text"
            >
            </b-form-input>
          </b-form-group>
        </div>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button
        variant="primary"
        :disabled="isLoading"
        @click="processData"
      >
        <b-spinner
          v-if="isLoading"
          small
          label="Small Spinner"
        ></b-spinner>
        {{ $t('general.print') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>

export default {
  data() {
    return {
      customer: '',
      object: '',
      pos: '',
      amount: '',
      isLoading: false,
    };
  },
  methods: {
    processData() {
      this.isLoading = true;
      const data = {
        bearingType: this.$store.state.fixedBearingSelectedBearingType,
        customer: this.customer,
        object: this.object,
        position: this.pos,
        amount: this.amount,
        pressureForce: this.$outputHelper.convertToNumber(
          this.$store.state.fixedBearingPressureForce,
        ),
        withSurcharge: this.$store.state.fixedBearingTwistingWithSurcharge,
        bearingThickness: this.$outputHelper.convertToNumber(
          this.$store.state.fixedBearingThickness,
        ),
        bearingWidth: this.$outputHelper.convertToNumber(this.$store.state.fixedBearingWidth),
        bearingLength: this.$outputHelper.convertToNumber(this.$store.state.fixedBearingLength),
        areaBrutto: this.$outputHelper.convertToNumber(this.$store.getters.areaBrutto),
        areaNetto: this.$outputHelper.convertToNumber(this.$store.getters.areaNetto),
        areaHoles: this.$outputHelper.convertToNumber(this.$store.getters.areaHoles),
        twistingTotalA: this.$outputHelper.convertToNumber(
          this.$store.getters.twisting.totalA,
        ),
        twistingTotalB: this.$outputHelper.convertToNumber(
          this.$store.getters.twisting.totalB,
        ),
        twistingAngleA: this.$calcHelper.getAngleOfRotation(
          this.$store.getters.twisting.totalA,
        ),
        twistingAngleB: this.$calcHelper.getAngleOfRotation(
          this.$store.getters.twisting.totalB,
        ),
        contactSurface: this.$store.state.fixedBearingTypeContactSurface,
        amountHoles: this.$outputHelper.convertToNumber(this.$store.state.fixedBearingHolesCount),
        diameter: this.$outputHelper.convertToNumber(this.$store.state.fixedBearingHolesDiameter),
        x1: this.$outputHelper.convertToNumber(this.$store.state.fixedBearingX1),
        y1: this.$outputHelper.convertToNumber(this.$store.state.fixedBearingY1),
        x2: this.$outputHelper.convertToNumber(this.$store.state.fixedBearingX2),
        y2: this.$outputHelper.convertToNumber(this.$store.state.fixedBearingY2),
        x3: this.$outputHelper.convertToNumber(this.$store.state.fixedBearingX3),
        y3: this.$outputHelper.convertToNumber(this.$store.state.fixedBearingY3),
        x4: this.$outputHelper.convertToNumber(this.$store.state.fixedBearingX4),
        y4: this.$outputHelper.convertToNumber(this.$store.state.fixedBearingY4),
        twistingA: this.$outputHelper.convertToNumber(this.$store.state.fixedBearingTwistingA),
        twistingB: this.$outputHelper.convertToNumber(this.$store.state.fixedBearingTwistingB),
        twistingRotationDistributionA: this.$outputHelper.convertToNumber(
          this.$store.getters.rotationDistribution.rotationDistributionA,
        ),
        twistingRotationDistributionB: this.$outputHelper.convertToNumber(
          this.$store.getters.rotationDistribution.rotationDistributionB,
        ),
        permissibleHorizontalDisplacement: this.$outputHelper.convertToNumber(
          this.$store.getters.permissibleHorizontalDisplacement,
        ),
        existingCompressiveStress: this.$outputHelper.convertToNumber(
          this.$store.getters.compressiveStress,
        ),
        existingPressureForce: this.$outputHelper.convertToNumber(
          this.$store.state.fixedBearingPressureForce,
        ),
        existingTwistingA: this.$outputHelper.convertToNumber(
          this.$store.getters.twisting.totalA,
        ),
        existingTwistingB: this.$outputHelper.convertToNumber(
          this.$store.getters.twisting.totalB,
        ),
        existingTwistingTotal: this.$outputHelper.convertToNumber(
          this.$store.getters.twistingTotal,
        ),
      };

      if (this.$store.state.fixedBearingSelectedBearingType === 'typ100') {
        data.permissiblePressureForce = this.$outputHelper.convertToNumber(
          this.$store.getters.typ100PermissiblePressureForce,
        );
        data.permissibleCompressiveStress = this.$outputHelper.convertToNumber(
          this.$store.getters.typ100PermissibleCompressiveStress,
        );
        data.permissibleTwistingA = this.$outputHelper.convertToNumber(
          this.$store.getters.typ100PermissibleTwisting.alphaA,
        );
        data.permissibleTwistingB = this.$outputHelper.convertToNumber(
          this.$store.getters.typ100PermissibleTwisting.alphaB,
        );
        data.permissibleTwistingResulting = this.$outputHelper.convertToNumber(
          this.$store.getters.typ100PermissibleAlphaResulting,
        );
      }
      if (this.$store.state.fixedBearingSelectedBearingType === 'typ150') {
        data.permissiblePressureForce = this.$outputHelper.convertToNumber(
          this.$store.getters.typ150PermissiblePressureForce,
        );
        data.permissibleCompressiveStress = this.$outputHelper.convertToNumber(
          this.$store.getters.typ150PermissibleCompressiveStress,
        );
        data.permissibleTwistingA = this.$outputHelper.convertToNumber(
          this.$store.getters.typ150PermissibleTwisting.alphaA,
        );
        data.permissibleTwistingB = this.$outputHelper.convertToNumber(
          this.$store.getters.typ150PermissibleTwisting.alphaB,
        );
        data.permissibleTwistingResulting = this.$outputHelper.convertToNumber(
          this.$store.getters.typ150PermissibleAlphaResulting,
        );
      }
      if (this.$store.state.fixedBearingSelectedBearingType === 'typ200') {
        data.permissiblePressureForce = this.$outputHelper.convertToNumber(
          this.$store.getters.typ200PermissiblePressureForce,
        );
        data.permissibleCompressiveStress = this.$outputHelper.convertToNumber(
          this.$store.getters.typ200PermissibleCompressiveStress,
        );
        data.permissibleTwistingA = this.$outputHelper.convertToNumber(
          this.$store.getters.typ200PermissibleTwisting.alphaA,
        );
        data.permissibleTwistingB = this.$outputHelper.convertToNumber(
          this.$store.getters.typ200PermissibleTwisting.alphaB,
        );
        data.permissibleTwistingResulting = this.$outputHelper.convertToNumber(
          this.$store.getters.typ200PermissibleAlphaResulting,
        );
      }
      if (this.$store.state.fixedBearingSelectedBearingType === 'pyramide') {
        data.pressureForce = this.$store.getters.pyramidePressureForce;
        data.permissiblePyramideCompressiveStress = this.$outputHelper.convertToNumber(
          this.$store.getters.pyramidePermissibleCompressiveStress,
        );
        data.permissiblePyramidePressureForce = this.$outputHelper.convertToNumber(
          this.$store.getters.pyramidePermissiblePressureForce,
        );
        data.permissiblePyramideTwisting = this.$outputHelper.convertToNumber(
          this.$store.getters.pyramidePermissibleTwisting,
        );
        data.permissiblePyramideTwistingAngle = this.$outputHelper.convertToNumber(
          this.$calcHelper.getPyramideAngleOfRotation(
            this.$store.getters.pyramidePermissibleTwisting,
          ),
        );
        data.permissiblePyramideHorizontalDisplacement = this.$outputHelper.convertToNumber(
          0.5,
        );
        data.existingPyramideCompressiveStress = this.$outputHelper.convertToNumber(
          this.$store.getters.pyramideCompressiveStressMax,
        );
        data.existingPyramidePressureForce = this.$outputHelper.convertToNumber(
          this.$store.getters.pyramidePressureForce,
        );
        data.existingPyramideTwisting = this.$outputHelper.convertToNumber(
          this.$store.getters.pyramideTwistingTotal,
        );
        data.existingPyramideTwistingAngle = this.$outputHelper.convertToNumber(
          this.$calcHelper.getPyramideAngleOfRotation(
            this.$store.getters.pyramideTwistingTotal,
          ),
        );
        data.pyramideTwistingTotal = this.$outputHelper.convertToNumber(
          this.$store.getters.pyramideTwistingTotal,
        );
        data.pyramideTwistingStatics = this.$outputHelper.convertToNumber(
          this.$store.state.fixedBearingPyramideTwisting,
        );
        data.pyramideTwistingImperfections = this.$outputHelper.convertToNumber(
          this.$store.getters.pyramideTwistingImperfections,
        );
        data.pyramideTwisting = this.$outputHelper.convertToNumber(
          this.$store.state.fixedBearingPyramideTwisting,
        );
        data.pyramideTwistingAngle = this.$outputHelper.convertToNumber(
          this.$calcHelper.getPyramideAngleOfRotation(
            this.$store.state.fixedBearingPyramideTwisting,
          ),
        );
        data.pyramideBearingClass = this.$outputHelper.convertToNumber(
          this.$store.state.fixedBearingPyramideStorageClass,
        );
        data.pyramideTwistingSide = this.$store.state.fixedBearingPyramideAxisOfRotation;
      }
      const options = {
        method: 'post',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
      };
      if (process.env.NODE_ENV !== 'development') {
        options.headers.Authorization = `Bearer ${document.querySelector('meta[name="csrf-token"]').getAttribute('content')}`;
      }
      fetch(`/api/bearing_calculation?language=${this.$i18n.locale}`, options)
        .then((response) => {
          if (!response.ok) { throw response; }
          return response.blob();
        })
        .then((blob) => {
          this.$outputHelper.downloadBlob(blob, `esz-lagerbemessung-${Date.now()}.pdf`);
        })
        .catch(() => {
          this.$bvToast.toast(this.$i18n.t('general.errorToastContent'), {
            autoHideDelay: 5000,
            variant: 'danger',
            solid: true,
            title: this.$i18n.t('general.errorToastTitle'),
            toaster: 'b-toaster-bottom-right',
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">

</style>
