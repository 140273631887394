<template>
  <div id="page-fixed-bearings-dimension">
    <div class="row">
      <div class="col-lg-6">
        <FixedBearingsFormEffects />
        <FixedBearingsFormBearingDimension />
        <FixedBearingsFormHoles />
        <!-- <FixedBearingsFormPyramide /> -->
        <FixedBearingsHelp />
      </div>
      <div class="col-lg-6">
        <FixedBearingsFormBearingSelection />
        <FixedBearingsFormVisual />
        <FixedBearingsFormDialogDimension />
      </div>
    </div>
  </div>
</template>

<script>
import FixedBearingsFormEffects from '@/components/fixedBearings/FixedBearingsFormEffects.vue';
import FixedBearingsFormBearingDimension from '@/components/fixedBearings/FixedBearingsFormBearingDimension.vue';
import FixedBearingsFormHoles from '@/components/fixedBearings/FixedBearingsFormHoles.vue';
// import FixedBearingsFormPyramide from '@/components/fixedBearings/FixedBearingsFormPyramide.vue';
import FixedBearingsFormDialogDimension from '@/components/fixedBearings/FixedBearingsFormDialogDimension.vue';
import FixedBearingsFormBearingSelection from '@/components/fixedBearings/FixedBearingsFormBearingSelection.vue';
import FixedBearingsFormVisual from '@/components/fixedBearings/FixedBearingsFormVisual.vue';
import FixedBearingsHelp from '@/components/fixedBearings/FixedBearingsHelp.vue';

export default {
  name: 'FixedBearingsDimension',
  components: {
    FixedBearingsFormVisual,
    FixedBearingsFormEffects,
    FixedBearingsFormBearingDimension,
    FixedBearingsFormHoles,
    FixedBearingsFormDialogDimension,
    FixedBearingsFormBearingSelection,
    FixedBearingsHelp,
  },
};
</script>

<style scoped lang="scss">

</style>
