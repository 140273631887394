<template>
  <div class="panel has-bg">
    <div class="row">
      <div class="col-md-6">
        <h3>{{ $t('bearings.headlineBearingScheme') }}</h3>
      </div>
      <div class="col-md-6">
        <b-form-group
          label-for="schemeType"
        >
          <b-input-group>
            <b-select
              id="schemeType"
              v-model="schemeType"
              :options="schemeTypeOptions"
            />
          </b-input-group>
        </b-form-group>
        <b-form-group
          label-for="showRotationAngles"
          v-if="schemeType == '3d'"
        >
          <b-input-group>
            <b-form-checkbox
              id="showRotationAngles"
              v-model="showRotationAngles"
              :value="true"
              :unchecked-value="false"
            >
              {{ $t('bearings.showRotationAngles') }}
            </b-form-checkbox>
          </b-input-group>
        </b-form-group>
      </div>
    </div>
    <div v-if="schemeType == '3d'">
      <FixedBearingsVisual3d :show-rotation-angles="showRotationAngles" />
    </div>
    <div
      v-if="schemeType == '2d'"
      class="m-auto w-75"
    >
      <div v-if="$i18n.locale === 'de'">
        <img
          v-if="holesCount == '0'"
          src="../../assets/img/abbildungen/lagerausbildung.jpg"
          :alt="$t('bearings.headlineBearingScheme')"
        />
        <img
          v-if="holesCount == '1'"
          src="../../assets/img/abbildungen/lagerausbildung-1bohrung.jpg"
          :alt="$t('bearings.headlineBearingScheme')"
        />
        <img
          v-if="holesCount == '2'"
          src="../../assets/img/abbildungen/lagerausbildung-2bohrungen.jpg"
          :alt="$t('bearings.headlineBearingScheme')"
        />
        <img
          v-if="holesCount == '3'"
          src="../../assets/img/abbildungen/lagerausbildung-3bohrungen.jpg"
          :alt="$t('bearings.headlineBearingScheme')"
        />
        <img
          v-if="holesCount == '4'"
          src="../../assets/img/abbildungen/lagerausbildung-4bohrungen.jpg"
          :alt="$t('bearings.headlineBearingScheme')"
        />
      </div>
      <div v-else>
        <img
          v-if="holesCount == '0'"
          src="../../assets/img/abbildungen/lagerausbildung-en.png"
          :alt="$t('bearings.headlineBearingScheme')"
        />
        <img
          v-if="holesCount == '1'"
          src="../../assets/img/abbildungen/lagerausbildung-1bohrung-en.png"
          :alt="$t('bearings.headlineBearingScheme')"
        />
        <img
          v-if="holesCount == '2'"
          src="../../assets/img/abbildungen/lagerausbildung-2bohrungen-en.png"
          :alt="$t('bearings.headlineBearingScheme')"
        />
        <img
          v-if="holesCount == '3'"
          src="../../assets/img/abbildungen/lagerausbildung-3bohrungen-en.png"
          :alt="$t('bearings.headlineBearingScheme')"
        />
        <img
          v-if="holesCount == '4'"
          src="../../assets/img/abbildungen/lagerausbildung-4bohrungen-en.png"
          :alt="$t('bearings.headlineBearingScheme')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FixedBearingsVisual3d from './visual/FixedBearingsVisual3d.vue';

export default {
  name: 'FixedBearingsFormVisual',
  components: {
    FixedBearingsVisual3d,
  },
  data() {
    return {
      schemeTypeOptions: Object.values(this.$dataOptionsHelper.getSchemeTypeOptions(this.$i18n)),
      showRotationAngles: false,
    };
  },
  computed: {
    holesCount() {
      return this.$outputHelper.convertToInteger(this.$store.state.fixedBearingHolesCount);
    },
    schemeType: {
      get() {
        return this.$store.state.fixedBearingSchemeType;
      },
      set(value) {
        this.$store.commit('fixedBearingSchemeType', value);
      },
    },
  },
};
</script>
