<template>
  <div id="containerVisual3d"></div>
</template>

<script>
import * as THREE from 'three';
import OrbitControls from 'orbit-controls-es6';
// import { CSS2DObject, CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer';
import fontJson from '../../../assets/fonts/open_sans.json';

export default {
  name: 'FixedBearingsVisual3d',
  props: {
    propBearingWidth: {
      type: Number,
      default: 120,
    },
    propBearingLength: {
      type: Number,
      default: 200,
    },
    propHolesCount: {
      type: Number,
      default: 2,
    },
    propHolesDiameter: {
      type: Number,
      default: 40,
    },
    propBearingThickness: {
      type: Number,
      default: 10,
    },
    showRotationAngles: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      scene: null,
      camera: null,
      light: null,
      renderer: null,
      animationFrameId: null,
    };
  },
  computed: {
    bearingThickness() {
      return this.$outputHelper.convertToInteger(this.$store.state.fixedBearingThickness);
    },
    holesDiameter() {
      return this.$outputHelper.convertToInteger(this.$store.state.fixedBearingHolesDiameter);
    },
    bearingWidth() {
      return this.$outputHelper.convertToInteger(this.$store.state.fixedBearingWidth);
    },
    bearingLength() {
      return this.$outputHelper.convertToInteger(this.$store.state.fixedBearingLength);
    },
    holesCount() {
      return this.$outputHelper.convertToInteger(this.$store.state.fixedBearingHolesCount);
    },
    holesPosition() {
      return this.$store.state.fixedBearingHolesPosition;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    showRotationAngles() {
      this.initScheme3d();
    },
    // eslint-disable-next-line func-names
    '$store.state.fixedBearingWidth': function () {
      this.initScheme3d();
    },
    // eslint-disable-next-line func-names
    '$store.state.fixedBearingLength': function () {
      this.initScheme3d();
    },
    // eslint-disable-next-line func-names
    '$store.state.fixedBearingHolesPosition': function () {
      this.initScheme3d();
    },
    // eslint-disable-next-line func-names
    '$store.state.fixedBearingHolesDiameter': function () {
      this.initScheme3d();
    },
    // eslint-disable-next-line func-names
    '$store.state.fixedBearingHolesCount': function () {
      this.initScheme3d();
    },
    // eslint-disable-next-line func-names
    '$store.state.fixedBearingThickness': function () {
      this.initScheme3d();
    },
    // eslint-disable-next-line func-names

    '$store.state.fixedBearingX1': function () {
      this.initScheme3d();
    },
    // eslint-disable-next-line func-names
    '$store.state.fixedBearingY1': function () {
      this.initScheme3d();
    },
    // eslint-disable-next-line func-names
    '$store.state.fixedBearingX2': function () {
      this.initScheme3d();
    },
    // eslint-disable-next-line func-names
    '$store.state.fixedBearingY2': function () {
      this.initScheme3d();
    },
    // eslint-disable-next-line func-names
    '$store.state.fixedBearingX3': function () {
      this.initScheme3d();
    },
    // eslint-disable-next-line func-names
    '$store.state.fixedBearingY3': function () {
      this.initScheme3d();
    },
    // eslint-disable-next-line func-names
    '$store.state.fixedBearingX4': function () {
      this.initScheme3d();
    },
    // eslint-disable-next-line func-names
    '$store.state.fixedBearingY4': function () {
      this.initScheme3d();
    },
  },
  mounted() {
    this.initScheme3d();
  },
  destroyed() {
    this.scene = null;
    this.camera = null;
    this.light = null;
    this.renderer = null;
    cancelAnimationFrame(this.animationFrameId);
  },
  methods: {
    initScheme3d() {
      const canvas = document.querySelector('#containerVisual3d canvas');
      if (canvas) {
        document.querySelector('#containerVisual3d canvas').remove();
      }
      this.scene = null;
      this.camera = null;
      this.light = null;
      this.renderer = null;
      const modelContainer = document.getElementById('containerVisual3d');
      // Scene
      this.scene = new THREE.Scene();
      this.scene.background = new THREE.Color(0xEEEEEE);
      // Camera
      this.camera = new THREE.PerspectiveCamera(
        50,
        modelContainer.clientWidth / modelContainer.clientHeight,
        0.1,
        1000,
      );
      this.camera.position.set(0, 150, 400);
      this.scene.add(this.camera);
      // Light
      this.light = new THREE.PointLight(0xffffff, 0.8);
      this.camera.add(this.light);
      // Group
      const group = new THREE.Group();
      this.scene.add(group);

      // Rectangle
      const rectLength = this.bearingLength;
      const rectWidth = this.bearingWidth;

      const rectShape = new THREE.Shape()
        .moveTo(0, 0)
        .lineTo(rectLength, 0)
        .lineTo(rectLength, rectWidth)
        .lineTo(0, rectWidth)
        .lineTo(0, 0);

      // Holes
      /*
      if (this.holesPosition.hole1) {
        console.log('hole1', this.holesPosition.hole1.x, this.holesPosition.hole1.y);
      }
      if (this.holesPosition.hole2) {
        console.log('hole2', this.holesPosition.hole2.x, this.holesPosition.hole2.y);
      }
      if (this.holesPosition.hole3) {
        console.log('hole3', this.holesPosition.hole3.x, this.holesPosition.hole3.y);
      }
      if (this.holesPosition.hole4) {
        console.log('hole4', this.holesPosition.hole4.x, this.holesPosition.hole4.y);
      }
      */
      /*
      if (this.holesCount >= 1 && this.holesPosition.hole1) {
        const hole = new THREE.Path()
          .moveTo(this.holesPosition.hole1.x, this.holesPosition.hole1.y)
          .absarc(
            this.holesPosition.hole1.x,
            this.holesPosition.hole1.y,
            (this.holesDiameter / 2), 0, Math.PI * 2,
            true,
          );
        rectShape.holes.push(hole);
      }

      if (this.holesCount >= 2 && this.holesPosition.hole2) {
        const hole2 = new THREE.Path()
          .moveTo(this.holesPosition.hole2.x, this.holesPosition.hole2.y)
          .absarc(
            this.holesPosition.hole2.x,
            this.holesPosition.hole2.y,
            (this.holesDiameter / 2),
            0,
            Math.PI * 2,
            true,
          );
        rectShape.holes.push(hole2);
      }

      if (this.holesCount >= 3 && this.holesPosition.hole3) {
        const hole3 = new THREE.Path()
          .moveTo(this.holesPosition.hole3.x, this.holesPosition.hole3.y)
          .absarc(
            this.holesPosition.hole3.x,
            this.holesPosition.hole3.y,
            (this.holesDiameter / 2),
            0,
            Math.PI * 2,
            true,
          );
        rectShape.holes.push(hole3);
      }

      if (this.holesCount >= 4 && this.holesPosition.hole4) {
        const hole4 = new THREE.Path()
          .moveTo(this.holesPosition.hole4.x, this.holesPosition.hole4.y)
          .absarc(
            this.holesPosition.hole4.x,
            this.holesPosition.hole4.y,
            (this.holesDiameter / 2),
            0,
            Math.PI * 2,
            true,
          );
        rectShape.holes.push(hole4);
      } */

      if (this.holesCount >= 1) {
        const hole = new THREE.Path()
          .moveTo(this.$store.state.fixedBearingX1, this.$store.state.fixedBearingY1)
          .absarc(
            this.$store.state.fixedBearingX1,
            this.$store.state.fixedBearingY1,
            (this.holesDiameter / 2), 0, Math.PI * 2,
            true,
          );
        rectShape.holes.push(hole);
      }

      if (this.holesCount >= 2) {
        const hole2 = new THREE.Path()
          .moveTo(this.$store.state.fixedBearingX2, this.$store.state.fixedBearingY2)
          .absarc(
            this.$store.state.fixedBearingX2,
            this.$store.state.fixedBearingY2,
            (this.holesDiameter / 2),
            0,
            Math.PI * 2,
            true,
          );
        rectShape.holes.push(hole2);
      }

      if (this.holesCount >= 3) {
        const hole3 = new THREE.Path()
          .moveTo(this.$store.state.fixedBearingX3, this.$store.state.fixedBearingY3)
          .absarc(
            this.$store.state.fixedBearingX3,
            this.$store.state.fixedBearingY3,
            (this.holesDiameter / 2),
            0,
            Math.PI * 2,
            true,
          );
        rectShape.holes.push(hole3);
      }

      if (this.holesCount >= 4) {
        const hole4 = new THREE.Path()
          .moveTo(this.$store.state.fixedBearingX4, this.$store.state.fixedBearingY4)
          .absarc(
            this.$store.state.fixedBearingX4,
            this.$store.state.fixedBearingY4,
            (this.holesDiameter / 2),
            0,
            Math.PI * 2,
            true,
          );
        rectShape.holes.push(hole4);
      }

      const extrudeSettings = {
        depth: this.bearingThickness,
        bevelEnabled: true,
        bevelSegments: 2,
        steps: 2,
        bevelSize: 1,
        bevelThickness: 1,
      };
      const geometry = new THREE.ExtrudeBufferGeometry(rectShape, extrudeSettings);
      geometry.center();
      geometry.rotateX(Math.PI * -0.5);
      const material = new THREE.MeshPhongMaterial({
        color: 0x222222,
      });
      const mesh = new THREE.Mesh(geometry, material);

      if (this.showRotationAngles) {
        // Rotationswinkel Alpha a
        mesh.add(new THREE.ArrowHelper(
          new THREE.Vector3(0, 15, -(this.bearingWidth / 2) - 30).normalize(),
          new THREE.Vector3(0, 15, -(this.bearingWidth / 2) - 30),
          1.0,
          0xff0000,
          10,
          10,
        ));

        mesh.add(new THREE.ArrowHelper(
          new THREE.Vector3(0, 15, (this.bearingWidth / 2) + 30).normalize(),
          new THREE.Vector3(0, 15, (this.bearingWidth / 2) + 30),
          1.0,
          0xff0000,
          10,
          10,
        ));

        const font = new THREE.FontLoader().parse(fontJson);
        const textGeo = new THREE.TextGeometry('α a', {
          font,
          height: 1,
          size: 5,
          weight: 'normal',
          curveSegments: 5,
          bevelThickness: 1,
          bevelSize: 0.3,
          bevelSegments: 3,
          bevelEnabled: true,
        });
        textGeo.computeBoundingBox();
        textGeo.computeVertexNormals();

        const text = new THREE.Mesh(textGeo, new THREE.MeshLambertMaterial({ color: 0xff0000 }));
        text.position.add(new THREE.Vector3(
          3,
          (this.bearingThickness / 2) + 5,
          -(this.bearingWidth / 2),
        ));
        text.castShadow = true;
        mesh.add(text);

        const materialLine = new THREE.LineDashedMaterial({
          color: 0xff0000, linewidth: 1, scale: 1, dashSize: 3, gapSize: 2,
        });
        const points = [];
        points.push(new THREE.Vector3(0, 15, -(this.bearingWidth / 2) - 30));
        points.push(new THREE.Vector3(0, 15, (this.bearingWidth / 2) + 30));
        const geometryLine = new THREE.BufferGeometry().setFromPoints(points);
        const line = new THREE.Line(geometryLine, materialLine);
        line.computeLineDistances();
        mesh.add(line);

        // Rotationswinkel Alpha b
        mesh.add(new THREE.ArrowHelper(
          new THREE.Vector3(-(this.bearingLength / 2) - 30, 15, 0).normalize(),
          new THREE.Vector3(-(this.bearingLength / 2) - 30, 15, 0),
          1.0,
          0xff0000,
          10,
          10,
        ));

        mesh.add(new THREE.ArrowHelper(
          new THREE.Vector3((this.bearingLength / 2) + 30, 15, 0).normalize(),
          new THREE.Vector3((this.bearingLength / 2) + 30, 15, 0),
          1.0,
          0xff0000,
          10,
          10,
        ));

        const fontB = new THREE.FontLoader().parse(fontJson);
        const textGeoB = new THREE.TextGeometry('α b', {
          font: fontB,
          height: 1,
          size: 5,
          weight: 'normal',
          curveSegments: 5,
          bevelThickness: 1,
          bevelSize: 0.3,
          bevelSegments: 3,
          bevelEnabled: true,
        });
        textGeoB.computeBoundingBox();
        textGeoB.computeVertexNormals();

        const textB = new THREE.Mesh(textGeoB, new THREE.MeshLambertMaterial({ color: 0xff0000 }));
        textB.position.add(new THREE.Vector3(
          -(this.bearingLength / 2),
          (this.bearingThickness / 2) + 5,
          5,
        ));
        textB.castShadow = true;
        mesh.add(textB);

        const materialLineB = new THREE.LineDashedMaterial({
          color: 0xff0000, linewidth: 1, scale: 1, dashSize: 3, gapSize: 2,
        });
        const pointsB = [];
        pointsB.push(new THREE.Vector3(-(this.bearingLength / 2) - 30, 15, 0));
        pointsB.push(new THREE.Vector3((this.bearingLength / 2) + 30, 15, 0));
        const geometryLineB = new THREE.BufferGeometry().setFromPoints(pointsB);
        const lineB = new THREE.Line(geometryLineB, materialLineB);
        lineB.computeLineDistances();
        mesh.add(lineB);
      }

      group.add(mesh);

      // Renderer
      this.renderer = new THREE.WebGLRenderer({ antialias: true });
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setSize(modelContainer.clientWidth, modelContainer.clientHeight);
      const controls = new OrbitControls(this.camera, this.renderer.domElement);
      controls.minDistance = 100;
      controls.maxDistance = 500;
      modelContainer.appendChild(this.renderer.domElement);

      const { renderer } = this;
      const { scene } = this;
      const { camera } = this;

      function animate() {
        requestAnimationFrame(animate);
        renderer.render(scene, camera);
      }
      this.animationFrameId = animate();
      function onWindowResize() {
        camera.aspect = modelContainer.clientWidth / modelContainer.clientHeight;
        renderer.setSize(modelContainer.clientWidth, modelContainer.clientHeight);
        camera.updateProjectionMatrix();
      }
      window.addEventListener('resize', onWindowResize, false);
    },
  },
};
</script>

<style scoped>
  #containerVisual3d {
    width:100%;
    height:400px;
  }
</style>
