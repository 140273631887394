<template>
  <div class="panel has-bg">
    <h3>{{ $t('bearings.headlineBearingDimension') }}</h3>
    <div class="row">
      <!-- Lagerbeite -->
      <div class="col-sm-4">
        <b-form-group
          label-for="bearingWidth"
        >
          <template slot="label">
            {{ $t('bearings.bearingWidth') }} <span class="text-muted">a</span>
            <b-button
              id="popover-width"
              variant="default"
            >
              <font-awesome-icon icon="question-circle" />
            </b-button>
            <b-popover
              target="popover-width"
              triggers="hover"
              placement="top"
            >
              <template v-slot:title>
                {{ $t('bearings.dimensionWidthTooltipHeadline') }}
              </template>
              <p>{{ $t('bearings.dimensionWidthTooltip') }}</p>
              <p v-html="$t('general.tooltipAdditionalInfo')"></p>
            </b-popover>
          </template>
          <b-input-group append="mm">
            <FormInputInteger
              id="bearingWidth"
              v-model="bearingWidth"
              @input="checkHolesPosition"
              @blur.native="updateVisual"
            />
          </b-input-group>
        </b-form-group>
      </div>
      <!-- Lagerlänge -->
      <div class="col-sm-4">
        <b-form-group
          label-for="bearingLength"
        >
          <template slot="label">
            {{ $t('bearings.bearingLength') }} <span class="text-muted">b</span>
          </template>
          <b-input-group append="mm">
            <FormInputInteger
              id="bearingLength"
              v-model="bearingLength"
              @input="checkHolesPosition"
              @blur.native="updateVisual"
            />
          </b-input-group>
        </b-form-group>
      </div>
      <!-- Lagerdicke -->
      <div class="col-sm-4">
        <b-form-group
          label-for="bearingThickness"
        >
          <template slot="label">
            {{ $t('bearings.bearingThickness') }} <span class="text-muted">t</span>
          </template>
          <b-input-group>
            <b-form-select
              v-model="bearingThickness"
              :options="bearingThicknessOptions"
              @change.native="updateVisual"
            >
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import FormInputInteger from '../app/form/FormInputInteger.vue';

export default {
  components: {
    FormInputInteger,
  },
  data() {
    return {
      bearingThicknessOptions: Object.values(this.$dataOptionsHelper.getBearingThicknessOptions()),
    };
  },
  computed: {
    bearingWidth: {
      get() {
        return this.$store.state.fixedBearingWidth;
      },
      set(value) {
        this.$store.commit('fixedBearingWidth', value);
      },
    },
    bearingLength: {
      get() {
        return this.$store.state.fixedBearingLength;
      },
      set(value) {
        this.$store.commit('fixedBearingLength', value);
      },
    },
    bearingThickness: {
      get() {
        return this.$store.state.fixedBearingThickness;
      },
      set(value) {
        this.$store.commit('fixedBearingThickness', value);
      },
    },
  },
  methods: {
    checkHolesPosition() {
      const pos = this.$calcHelper.getHolesPosition(
        this.$store.state.fixedBearingWidth,
        this.$store.state.fixedBearingLength,
        this.$store.state.fixedBearingHolesCount,
        this.$store.state.fixedBearingHolesDiameter,
      );
      this.$store.commit('fixedBearingHolesPosition', pos);

      this.$store.commit('fixedBearingX1', pos.hole1?.x ?? 0);
      this.$store.commit('fixedBearingY1', pos.hole1?.y ?? 0);
      this.$store.commit('fixedBearingX2', pos.hole2?.x ?? 0);
      this.$store.commit('fixedBearingY2', pos.hole2?.y ?? 0);
      this.$store.commit('fixedBearingX3', pos.hole3?.x ?? 0);
      this.$store.commit('fixedBearingY3', pos.hole3?.y ?? 0);
      this.$store.commit('fixedBearingX4', pos.hole4?.x ?? 0);
      this.$store.commit('fixedBearingY4', pos.hole4?.y ?? 0);
    },
    updateVisual() {
      const value = this.$store.state.fixedBearingUpdateVisual + 1;
      this.$store.commit('fixedBearingUpdateVisual', value);
    },
  },
};
</script>

<style scoped lang="scss">

</style>
